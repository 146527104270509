import React, { Component } from "react";

import SignupSection from "../components/Signup/Signup";

class Signup extends Component {
  render() {
    return (
      <div className="main">
        <SignupSection />
      </div>
    );
  }
}

export default Signup;
