import React, { Component } from "react";
import LoginSection from "../components/Login/Login";

class Login extends Component {
  render() {
    return (
      <div className="main">
        <LoginSection />
      </div>
    );
  }
}

export default Login;
