import React from "react";

const FillHeart = () => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.8593 3.81868H10.999C12.4663 3.88462 13.6541 5.00549 13.5842 6.45604C13.5842 6.52198 13.5842 6.58791 13.5842 6.65385C13.3746 10.478 8.9029 12.9835 7.92471 13.1813C6.87666 12.9176 5.20961 6.73077 5 3.5C4.93013 2.70879 2.61456 5.26923 3.17352 4.67582C3.59275 4.21429 4.22158 3.95055 4.85042 3.95055C5.61899 4.01648 2.68443 9.97253 3.17352 10.5L7.85484 14L12.5 10.5C12.9891 9.97253 17 2.63187 10.8593 3.81868ZM10.8593 2.5C9.67148 2.56593 8.62342 3.09341 7.85484 3.95055C7.08627 3.15934 5.96835 2.6978 4.85042 2.63187C3.80236 2.63187 2.82416 3.02747 2.12546 3.75275C1.35688 4.60989 0.937667 5.73077 1.00754 6.91758C1.28702 11.0714 6.59717 14.5 7.85484 14.5H7.99458C9.25225 14.5 14.7021 11.4011 14.9816 6.71978C15.1912 4.60989 13.5842 2.6978 11.2785 2.5C11.2086 2.5 11.1388 2.5 11.1388 2.5H10.8593Z"
        fill="#dc3545"
      />
    </svg>
  );
};

export default FillHeart;

